import React, { useState, useEffect } from "react";
import "mapbox-gl/dist/mapbox-gl.css";
import "./SensePlusMap.css";
import { Spin, Button, Input, AutoComplete } from "antd";
import { LoadingOutlined, DoubleLeftOutlined, DoubleRightOutlined } from "@ant-design/icons";

const { Search } = Input;

function SensePlusMapLegend({
  tickets,
  hasMore,
  sidebarOpen,
  toggleSidebar,
  setFilterString,
  setStateFilter,
  mapRef,
}) {
  const [filteredEsgTickets, setFilteredEsgTickets] = useState([]);
  const [filteredAlertTickets, setFilteredAlertTickets] = useState([]);
  const [searchOptions, setSearchOptions] = useState([]);

  const initialOptions = [
    { value: "Acre" },
    { value: "Alagoas" },
    { value: "Amapá" },
    { value: "Amazonas" },
    { value: "Bahia" },
    { value: "Ceará" },
    { value: "Distrito Federal" },
    { value: "Espírito Santo" },
    { value: "Goiás" },
    { value: "Maranhão" },
    { value: "Mato Grosso" },
    { value: "Mato Grosso do Sul" },
    { value: "Minas Gerais" },
    { value: "Pará" },
    { value: "Paraíba" },
    { value: "Paraná" },
    { value: "Pernambuco" },
    { value: "Piauí" },
    { value: "Rio de Janeiro" },
    { value: "Rio Grande do Norte" },
    { value: "Rio Grande do Sul" },
    { value: "Rondônia" },
    { value: "Roraima" },
    { value: "Santa Catarina" },
    { value: "São Paulo" },
    { value: "Sergipe" },
    { value: "Tocantins" },
  ];

  useEffect(() => {
    const esgFiltered = tickets.filter((ticket) => ticket.sicarOverlap);
    setFilteredEsgTickets(esgFiltered);

    const alertFiltered = tickets.filter((ticket) => ticket.notification.notification_type);
    setFilteredAlertTickets(alertFiltered);
  }, [tickets]);

  const handleToggleSidebar = () => {
    toggleSidebar();
    setTimeout(() => {
      if (mapRef.current) {
        mapRef.current.resize();
      }
    }, 450);
  };

  const handleSearch = (value) => {
    if (!value) {
      setSearchOptions(initialOptions);
      return;
    }
    const filteredOptions = initialOptions.filter((option) =>
      option.value.toLowerCase().includes(value.toLowerCase())
    );
    setSearchOptions(filteredOptions);
  };

  useEffect(() => {
    setSearchOptions(initialOptions);
  }, []);

  return (
    <div className="sensePlusFilterContainer">
      <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
        <Button
          className="sensePlusFilterButton"
          onClick={handleToggleSidebar}
          icon={sidebarOpen ? <DoubleLeftOutlined /> : <DoubleRightOutlined />}
        />
        <AutoComplete
          popupClassName="certain-category-search-dropdown"
          style={{ width: "100%" }}
          options={searchOptions}
          onSearch={handleSearch}
          onSelect={(value) => setStateFilter(value)}
          size="large"
        >
          <Search
            className="sensePlusSearch"
            placeholder="Pesquise aqui..."
            size="large"
            onSearch={(value) => setFilterString(value)}
          />
        </AutoComplete>
      </div>
      <div className="sensePlusFilterCard">
        <div className="sensePlusFilterContent">
          <div>
            <span style={{ color: "#1890FF" }}>Contratos Monitorados:</span> {tickets.length}
            {hasMore && (
              <Spin
                indicator={
                  <LoadingOutlined
                    style={{
                      fontSize: 20,
                      color: "#ffffffd9",
                    }}
                    spin
                  />
                }
              />
            )}
          </div>
          <div>
            <span style={{ color: "#FAAD14" }}>Contratos em Alerta:</span>{" "}
            {filteredAlertTickets.length}
          </div>
          <div>
            <span style={{ color: "#FF4D4F" }}>Contratos com ESG:</span>{" "}
            {filteredEsgTickets.length}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SensePlusMapLegend;
