import React from "react";
import ReactApexChart from "react-apexcharts";
import {DoubleRightOutlined} from "@ant-design/icons";
import {Button, Tooltip} from "antd";
import {CenterFocusStrong} from "@mui/icons-material";

const PlantingProgress = ({
                              cropStages,
                              cropStageFilter,
                              onCropStageFilterChange,
                          }) => {
    const series1 = cropStages.map((stage) => stage.percentage);
    const labels1 = cropStages.map((stage) => stage.name);
    const colors1 = ["#34A853", "#FBBC05", "#4285F4", "#EA4335"];

    const prettyLabels = {
        sowed: "Plantado",
        close_harvested: "Prox. Colheita",
        harvested: "Colhido",
        unidentified: "Não identificado",
    };

    const graphLabels = labels1.map((label) => prettyLabels[label] || label);

    const options1 = {
        chart: {
            type: "pie",
            background: "#141414",
        },
        labels: graphLabels,
        legend: {
            show: false,
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            show: false,
        },
        theme: {
            mode: "dark",
        },
        colors: colors1,
    };

    return (
        <div style={{paddingLeft: "20px", color: "#FFF"}}>
            <h3
                style={{
                    fontFamily: "Outfit",
                    fontSize: "20px",
                    fontWeight: 600,
                    lineHeight: "28px",
                    textAlign: "left",
                    marginBottom: "16px",
                    paddingLeft: "24px",
                }}
            >
                Progressão do Plantio
            </h3>

            {/* Gráfico */}
            <div
                style={{
                    marginBottom: "16px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <ReactApexChart
                    options={options1}
                    series={series1}
                    height={152}
                    width={152}
                    type="pie"
                />
            </div>

            {/* Tabela de Legenda */}
            <div>
                <table
                    style={{
                        width: "100%",
                        borderCollapse: "collapse",
                        fontFamily: "Outfit",
                    }}
                >
                    <thead></thead>
                    <tbody>
                    {cropStages.map((stage, index) => (
                        <Tooltip key={index} title={`Área: ${stage.area} ha`} color="#9f45dd" placement="topRight">
                            <tr
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    paddingTop: "8px",
                                    paddingLeft: "20px",
                                    backgroundColor:
                                        stage.name === cropStageFilter ? "#434343" : "#141414",
                                }}
                            >
                                <td
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: "8px",
                                        flex: 1,
                                    }}
                                >
                    <span
                        style={{
                            width: "12px",
                            height: "12px",
                            backgroundColor: colors1[index],
                            display: "inline-block",
                            borderRadius: "50%",
                        }}
                    ></span>
                                    {prettyLabels[stage.name]}
                                </td>
                                <td
                                    style={{
                                        textAlign: "right",
                                        flex: 1,
                                    }}
                                >
                                    {series1[index]}%
                                </td>
                                <td
                                    style={{
                                        textAlign: "right",
                                        flex: 0,
                                    }}
                                >
                                    <Button
                                        className="sensePlusFilterButtonPiePannel"
                                        icon={<DoubleRightOutlined/>}
                                        onClick={() => onCropStageFilterChange(stage.name)}
                                    />
                                </td>
                            </tr>
                        </Tooltip>
                    ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default PlantingProgress;
