import React from "react";
import ReactApexChart from "react-apexcharts";
import {Tooltip, Button} from "antd";
import {DoubleRightOutlined} from "@ant-design/icons";

const CropDistributionChart = ({
                                   cropCultures,
                                   cropFilter,
                                   onCropFilterChange,
                               }) => {
    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    };
    const series1 = cropCultures.map((culture) => culture.percentage);
    const labels1 = cropCultures.map((culture) => capitalizeFirstLetter(culture.name));
    const colors1 = ["#90BE6D", "#F9C74F", "#81AEF7", "#F3722C"];

    const options1 = {
        chart: {
            type: "pie",
            background: "#141414",
        },
        labels: labels1,
        legend: {
            show: false,
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            show: false,
        },
        theme: {
            mode: "dark",
        },
        colors: colors1,
    };

    return (
        <div style={{paddingLeft: "20px", color: "#FFF"}}>
            <h3
                style={{
                    fontFamily: "Outfit",
                    fontSize: "20px",
                    fontWeight: 600,
                    lineHeight: "28px",
                    textAlign: "left",
                    marginBottom: "16px",
                    paddingLeft: "24px",
                }}
            >
                Distribuição de Culturas
            </h3>

            {/* Gráfico */}
            <div
                style={{
                    marginBottom: "16px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <ReactApexChart
                    options={options1}
                    series={series1}
                    height={152}
                    width={152}
                    type="pie"
                />
            </div>

            {/* Tabela de Legenda */}
            <div>
                <table
                    style={{
                        width: "100%",
                        borderCollapse: "collapse",
                        fontFamily: "Outfit",
                    }}
                >
                    <thead></thead>
                    <tbody>
                    {labels1.map((label, index) => (
                        <Tooltip
                            key={index}
                            title={`Área: ${cropCultures[index].area} ha`}
                            color="#9f45dd"
                            placement="topRight"
                        >
                            <tr
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    paddingTop: "8px",
                                    paddingLeft: "20px",
                                    backgroundColor:
                                        label === cropFilter ? "#434343" : "#141414",
                                }}
                            >
                                <td
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: "8px",
                                        flex: 1,
                                    }}
                                >
                    <span
                        style={{
                            width: "12px",
                            height: "12px",
                            backgroundColor: colors1[index],
                            display: "inline-block",
                            borderRadius: "50%",
                        }}
                    ></span>
                                    {label}
                                </td>
                                <td
                                    style={{
                                        textAlign: "right",
                                        flex: 1,
                                    }}
                                >
                                    {series1[index]}%
                                </td>
                                <td
                                    style={{
                                        textAlign: "right",
                                        flex: 0,
                                    }}
                                >
                                    <Button
                                        className="sensePlusFilterButtonPiePannel"
                                        icon={<DoubleRightOutlined/>}
                                        onClick={() => onCropFilterChange(label)}
                                    />
                                </td>
                            </tr>
                        </Tooltip>
                    ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default CropDistributionChart;
