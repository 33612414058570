import React, { useState, useEffect, useRef  } from "react";
import ReactDOM from "react-dom";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { useNavigate } from "react-router-dom";
import "./SensePlusMap.css";
import SensePlusPopover from "./SensePlusPopover";
import { Button } from "antd";
import { MonitorOutlined } from "@ant-design/icons";
import SensePlusMapLegend from "./SensePlusMapLegend";

function SensePlusMap({
  tickets,
  mapboxToken,
  hasMore,
  sidebarOpen,
  toggleSidebar,
  setFilterString,
  setStateFilter,
  mapRef,
}) {
  const mapContainerRef = useRef(null);
  const popupRef = useRef(null);
  const [popupInfo, setPopupInfo] = useState(null);
  const [isMapLoaded, setIsMapLoaded] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (!mapboxToken) return;

    mapboxgl.accessToken = mapboxToken;
    mapRef.current = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: "mapbox://styles/felipegalo/cluhf94ix00zu01qlhwrx8oyq",
      center: [-52, -27],
      zoom: 6,
    });

    mapRef.current.on("load", () => {
      mapRef.current.addSource("tickets", {
        type: "geojson",
        data: {
          type: "FeatureCollection",
          features: [],
        },
        cluster: true,
        clusterMaxZoom: 14,
        clusterRadius: 50,
      });

      mapRef.current.addLayer({
        id: "clusters",
        type: "circle",
        source: "tickets",
        filter: ["has", "point_count"],
        paint: {
          "circle-color": [
            "step",
            ["get", "point_count"],
            "#2288B4",
            10,
            "#FF7B00",
            30,
            "#F5222D",
          ],
          "circle-radius": [
            "step",
            ["get", "point_count"],
            12,
            16,
            24,
            28,
            32,
          ],
          "circle-stroke-width": 1,
          "circle-stroke-color": "#fff",
        },
      });

      mapRef.current.addLayer({
        id: "cluster-count",
        type: "symbol",
        source: "tickets",
        filter: ["has", "point_count"],
        layout: {
          "text-field": "{point_count_abbreviated}",
          "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
          "text-size": 12,
        },
        paint: {
          "text-color": "#ffffff",
        },
      });

      mapRef.current.addLayer({
        id: "unclustered-points",
        type: "circle",
        source: "tickets",
        filter: ["!", ["has", "point_count"]],
        paint: {
          "circle-color": [
            "case",
            ["get", "sicarOverlap"],
            "#F5222D",
            ["get", "hasNotification"],
            "#FF7B00",
            "#2288B4",
          ],
          "circle-radius": 6,
          "circle-stroke-width": 1,
          "circle-stroke-color": "#fff",
        },
      });

      // Setup interactivity
      mapRef.current.on("click", "clusters", (e) => {
        const features = mapRef.current.queryRenderedFeatures(e.point, {
          layers: ["clusters"],
        });
        const clusterId = features[0].properties.cluster_id;

        mapRef.current.getSource("tickets").getClusterExpansionZoom(
          clusterId,
          (err, zoom) => {
            if (err) return;

            mapRef.current.easeTo({
              center: features[0].geometry.coordinates,
              zoom: zoom,
            });
          }
        );
      });

      mapRef.current.on("click", "unclustered-points", (e) => {
        const feature = e.features[0];

        setPopupInfo({
          id: feature.properties.id,
          clientId: feature.properties.clientId || "N/A",
          proposalId: feature.properties.proposalId || "N/A",
          area: feature.properties.area || 0,
          sicarOverlap: feature.properties.sicarOverlap,
          notification: feature.properties.notification,
          long: feature.geometry.coordinates[0],
          lat: feature.geometry.coordinates[1],
        });
      });

      mapRef.current.on("mouseenter", "clusters", () => {
        mapRef.current.getCanvas().style.cursor = "pointer";
      });

      mapRef.current.on("mouseleave", "clusters", () => {
        mapRef.current.getCanvas().style.cursor = "";
      });

      setIsMapLoaded(true);
    });

    return () => {
      if (mapRef.current) mapRef.current.remove();
    };
  }, [mapboxToken]);

  useEffect(() => {
    if (mapRef.current && tickets) {
      const geoJsonData = {
        type: "FeatureCollection",
        features: tickets.map((ticket) => ({
          type: "Feature",
          properties: {
            id: ticket.id,
            clientId: ticket.clientId,
            proposalId: ticket.proposalId,
            area: ticket.area,
            notification: ticket.notification.notification_type || "",
            hasNotification: !!ticket.notification.notification_type,
            sicarOverlap: ticket.sicarOverlap,
          },
          geometry: {
            type: "Point",
            coordinates: [ticket.long, ticket.lat],
          },
        })),
      };

      const source = mapRef.current.getSource("tickets");
      if (source) {
        source.setData(geoJsonData);
      }
    }
  }, [tickets, isMapLoaded]);

  useEffect(() => {
    if (popupInfo && mapRef.current) {
      if (popupRef.current) {
        popupRef.current.remove();
      }

      const popupNode = document.createElement("div");
      ReactDOM.render(
        <div className="sensePlusPopupContainer">
          <SensePlusPopover ticket={popupInfo} />
          <Button
            className="sensePlusPopupButton"
            icon={<MonitorOutlined />}
            onClick={() => navigate(`/sense/${popupInfo.id}`)}
          >
            Ver no monitoramento
          </Button>
        </div>,
        popupNode
      );

      popupRef.current = new mapboxgl.Popup({
        closeOnClick: false,
        closeButton: true,
        anchor: "bottom",
        className: "sensePlusPopup",
      })
        .setLngLat([popupInfo.long, popupInfo.lat])
        .setDOMContent(popupNode)
        .addTo(mapRef.current);

      popupRef.current.on("close", () => setPopupInfo(null));
    }
  }, [popupInfo]);

  return (
    <div className="mapVis" style={{ height: "100%", width: "100%" }}>
      <div ref={mapContainerRef} style={{ height: "100%", width: "100%", backgroundColor: "#141414" }} />
      <SensePlusMapLegend
        tickets={tickets}
        hasMore={hasMore}
        sidebarOpen={sidebarOpen}
        toggleSidebar={toggleSidebar}
        setFilterString={setFilterString}
        setStateFilter={setStateFilter}
        mapRef={mapRef}
      />
    </div>
  );
  
}

export default SensePlusMap;