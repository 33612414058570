import React from "react";
import {useNavigate} from "react-router-dom";
import {Table, Button, Tooltip} from "antd";
import {DownloadOutlined} from "@ant-design/icons";
import * as Papa from "papaparse";
import atypicalEventsContent from "../dashboard/AtypicalEventsContent";

const TicketTable = ({data, mapRef, hasMore}) => {
    const navigate = useNavigate();

    const prettyCrops = {
        "soja": "Soja",
        "milho": "Milho",
        "arroz": "Arroz",
        "outros": "Outros"
    }

    const columns = [
        {
            title: "ID do Ticket",
            dataIndex: "id",
            key: "id",
            width: 100,
        },
        {
            title: "ID do Cliente",
            dataIndex: "clientId",
            key: "clientId",
            width: 160,
        },
        {
            title: "Localização",
            dataIndex: "location",
            key: "location",
            width: 200,
        },
        {
            title: "Cultura",
            dataIndex: "cropType",
            key: "cropType",
            width: 100,
            render: (value) => prettyCrops[value] || value,
        },
        {
            title: "UF",
            dataIndex: "state",
            key: "state",
            width: 60,
        },
        {
            title: "Município",
            dataIndex: "municipality",
            key: "municipality",
            width: 200,
        },
        {
            title: "Área (ha)",
            dataIndex: "area",
            key: "area",
            width: 120,
            render: (value) => `${value} ha`,
        },
        {
            title: "Opções",
            key: "actions",
            render: (_, record) => (
                <div>
                    <Button
                        type="link"
                        style={{color: "#1890ff"}}
                        onClick={() => {
                            if (mapRef.current) {
                                mapRef.current.flyTo({
                                    center: [record.long, record.lat],
                                    zoom: 14,
                                });
                            }
                        }}
                    >
                        Ver no mapa
                    </Button>
                    <Button
                        type="link"
                        style={{color: "#1890ff"}}
                        onClick={() => navigate(`/sense/${record.id}`)}
                    >
                        Abrir Ticket
                    </Button>
                </div>
            ),
        },
    ];

    const exportToCSV = () => {
        const formatDate = (date) => {
            if (!date) return null;
            const formatter = new Intl.DateTimeFormat("pt-BR", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
            });
            return formatter.format(new Date(date));
        };

        const filteredData = data.map(({ location, notification, ...rest }) => {
            const formattedNotification = notification && notification.notification_type && notification.notification_date
                ? atypicalEventsContent[notification.notification_type]?.title
                    ? `Tipo: ${atypicalEventsContent[notification.notification_type].title}, Data: ${formatDate(notification.notification_date)}`
                    : "Tipo de alerta desconhecido"
                : "Sem alertas";
        
            return {
                ...rest,
                notification: formattedNotification,
            };
        });        

        const csv = Papa.unparse(filteredData);
        const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "tickets.csv");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <div style={{position: "relative "}}>

            <div
                style={{
                    position: "fixed",
                    bottom: "121px",
                    right: "176px",
                    zIndex: 1000,

                }}
            >
                <Tooltip title={hasMore ? "Aguarde enquanto buscamos os dados do nosso servidor" : ""}>
                    <Button
                        type="primary"
                        icon={<DownloadOutlined/>}
                        onClick={exportToCSV}
                        className="ticket-Table-CSV"
                        disabled={hasMore}
                    >
                        Baixar Tabela
                    </Button>
                </Tooltip>
            </div>


            <Table
                className="ticket-table"
                columns={columns}
                dataSource={data}
                pagination={false}
                bordered={false}
                size="small"
                scroll={{y: 240}}
                virtual
                rowHoverable={false}
            />
        </div>
    );

};

export default TicketTable;