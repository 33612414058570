import React from "react";
import CardComponent from "./CardComponent.js";

const ListCard = ({ data, listUpdateOnDelete }) => {
    let isDisabled =
        data.aura_status === true && data.risk_calculation_status != "success";

    const handleListUpdateOnDelete = () => {
        listUpdateOnDelete();
    };

    return (
        <section>
            <div className="elementFlexVertical" style={{ minWidth: "1080px" }}>
                <div
                    className="elementFlexVertical"
                    style={{
                        gap: 8,
                        marginBottom: "16px",
                        opacity: isDisabled ? 0.5 : 1,
                        pointerEvents: isDisabled ? "none" : "auto",
                    }}
                >
                    <CardComponent
                        key={data.id}
                        ticket={data}
                        listUpdateOnDelete={handleListUpdateOnDelete}
                    />
                </div>
            </div>
        </section>
    );
};

export default ListCard;
