import React, { useState } from "react";
import { Modal } from "antd";
import "./Home.css";
import UserGuideCard from "../components/userGuideNotion";
import "react-notion/src/styles.css";
import "prismjs/themes/prism-tomorrow.css";

function Home() {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedPageId, setSelectedPageId] = useState(null);

    const handleCardClick = (pageId) => {
        setSelectedPageId(pageId);
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    return (
        <div className="homeContainer">
            <h1 className="homeTitle">
                Bem vindo à<br />
                plataforma AgromAI
            </h1>
            <div className="homeUserGuide">
                <div className="homeUserGuideTitle">Guias de uso</div>
                <div className="homeUserGuideContent">
                    <img
                        src={`/upload-banner-white.png`}
                        className="UserGuidePage"
                        onClick={() =>
                            handleCardClick("11a124febfc5808b9a7be4f09e6a3cf9")
                        }
                    />
                    <img
                        src={`/aura-banner-white.png`}
                        className="UserGuidePage"
                        onClick={() =>
                            handleCardClick("11a124febfc580fab31ef2ad774546d6")
                        }
                    />
                    <img
                        src={`/sense-banner-white.png`}
                        className="UserGuidePage"
                        onClick={() =>
                            handleCardClick("11a124febfc580999a4ed3e7c96536e2")
                        }
                    />
                    <img
                        src={`/vision-banner-white.png`}
                        className="UserGuidePage"
                        onClick={() =>
                            handleCardClick("11a124febfc580adb835d96b1e4a4756")
                        }
                    />
                </div>
            </div>
            <div className="homeUserGuide">
                <div className="homeUserGuideTitle">Dúvidas frequentes</div>
                <div className="homeUserGuideContent">
                    <img
                        src={`/score-banner-white.png`}
                        className="UserGuidePage"
                        onClick={() =>
                            handleCardClick("40a34b95c1e844fa96b6e7541c0b6f1b")
                        }
                    />
                    <img
                        src={`/na-banner-white.png`}
                        className="UserGuidePage"
                        onClick={() =>
                            handleCardClick("f7adbf9381484e8a9adc843790cee42e")
                        }
                    />
                    <img
                        src={`/limitations-banner-white.png`}
                        className="UserGuidePage"
                        onClick={() =>
                            handleCardClick("11a124febfc580be92eaf0e97cc4ee32")
                        }
                    />
                    <img
                        src={`/clouc_cover_prod.png`}
                        className="UserGuidePage"
                        onClick={() =>
                            handleCardClick("b52580fdb5db4bd699d46bd57c7495b4")
                        }
                    />
                </div>
            </div>
            <Modal
                title="Guia do Usuário"
                open={isModalVisible}
                onCancel={handleCancel}
                width={"80%"}
                className="userGuideModal"
                style={{
                    body: { maxHeight: "78vh", overflowY: "scroll" },
                }}
                footer={null}
            >
                {selectedPageId && <UserGuideCard pageId={selectedPageId} />}
            </Modal>
        </div>
    );
}

export default Home;
