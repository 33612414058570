import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {
    Row, Col, Tabs, Flex, theme, Steps, Radio, Modal, Button, Divider,
    Typography, Segmented, Spin, Tooltip,
} from "antd";
import {
    LeftOutlined,
    CloudOutlined,
    RightOutlined,
    PrinterOutlined,
    DoubleLeftOutlined,
    DoubleRightOutlined,
    MenuFoldOutlined,
    DownloadOutlined,
    LoadingOutlined,
} from "@ant-design/icons";
import {Content} from "antd/es/layout/layout";
import ReactApexChart from "react-apexcharts";
import Map, {Source, Layer} from "react-map-gl";
import * as GeoTIFF from 'geotiff';

import "./SenseBasic.css";
import "../components/dashboard/PlantingWindow.css";
import {polygonStylePatterns} from "./polyStyles";
import LoadingPage from "../components/dashboard/LoadingPage";
import ESGCardsList from "../components/dashboard/ESGCardsList";
import Notifications from "../components/dashboard/Notifications";
import AtypicalEvents from "../components/dashboard/AtypicalEvents";
import PlantingWindow from "../components/dashboard/PlantingWindow";
import formatDate from "../components/dashboard/functions/formatDate";
import PrecipitationChart from "../components/dashboard/PrecipitationChart";
import {fetchSenseDatedReports, fetchTicketData, fetchTrueImageDownload} from "../components/api/api";
import {Compare, YardOutlined} from "@mui/icons-material";
import { booleanPointInPolygon } from '@turf/turf';

const {TabPane} = Tabs;
const {Title, Text} = Typography;

function SenseBasicReport() {
    const {id} = useParams();
    const {token} = theme.useToken();
    const [data, setData] = useState(null);
    const [coords, setCoords] = useState(null);
    const [retry, setRetry] = useState(0);
    const [mapBounds, setMapBounds] = useState([]);
    const [current, setCurrent] = useState(null);
    const [ndviData, setNdviData] = useState([]);
    const [reportId, setReportId] = useState("");
    const [notifications, setNotifications] = useState([]);
    const [atypicalEvents, setAtypicalEvents] = useState([]);
    const [reportDate, setReportDate] = useState("");
    const [selectedChart, setSelectedChart] = useState("growthCurve");
    const [avgProductivity, setAvgProductivity] = useState([]);
    const [highProductivity, setHighProductivity] = useState([]);
    const [lowProductivity, setLowProductivity] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [reportsArray, setReportsArray] = useState([]);
    const [minTempData, setMinTempData] = useState("");
    const [avgTempData, setAvgTempData] = useState("");
    const [maxTempData, setMaxTempData] = useState("");
    const [productivityDates, setProductivityDates] = useState([]);
    const [kmlGeometry, setKmlGeometry] = useState("");
    const [maxWindSpeed, setMaxWindSpeed] = useState("");
    const [senseParamsData, setSenseParamsData] = useState(null);
    const [formattedDate, setFormattedDate] = useState("");
    const [harvestMonitoringComposite, setHarvestMonitoringComposite] = useState("");
    const [trueImageTiff, setTrueImageTiff] = useState("");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedMap, setSelectedMap] = useState("plantedArea");
    const [geometryCoords, setGeometryCoords] = useState([]);
    const [precipitationData, setPrecipitationData] = useState([]);
    const [precipitationDate, setPrecipitationDate] = useState([]);
    const [totalPrecipitation, setTotalPrecipitation] = useState(0);
    const [zarcCompliance, setZarcCompliance] = useState([])
    const [estimatedSowingDate, setEstimatedSowingDate] = useState("");
    const [estimatedHarvestDate, setEstimatedHarvestDate] = useState("");
    const [sowedAreaPercentage, setSowedAreaPercentage] = useState(null);
    const [mapboxToken, setMapboxToken] = useState("");
    const [geoTiffBounds, setGeoTiffBounds] = useState(null);
    const [trueImageBounds, setTrueImageBounds] = useState(null);
    const [imageURL, setImageURL] = useState(null);
    const [trueImageURL, setTrueImageURL] = useState(null);
    const [chartData, setChartData] = useState({
        series: [], options: {},
    });
    const [viewport, setViewport] = useState({
        longitude: -30, latitude: -50, zoom: 14,
    });

    const isButtonDisabled = !estimatedHarvestDate;

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [ticketData, senseParams] = await Promise.all([fetchTicketData(id), fetchSenseDatedReports(id)]);
                setData(ticketData);
                setIsLoading(false);
                setSenseParamsData(senseParams);
                setFormattedDate(formatDate(ticketData.created_at));
                setViewport((prev) => ({
                    ...prev,
                    longitude: ticketData?.center_coords?.long,
                    latitude: ticketData?.center_coords?.lat,
                    zoom: getDynamicZoom(ticketData?.land_size.hectares),
                }));

                if (senseParams.results && senseParams.results.length > 0) {
                    const reports = senseParams.results.map(item => ({
                        label: new Date(item.report_date).toLocaleDateString('pt-BR'), key: item.id, data: item,
                    }));
                    setReportsArray(reports);
                    setCurrent(0);
                    setKmlGeometry(ticketData?.farm_data?.kml_document?.kml_polygon?.geometry || "");
                    setCoords([ticketData.center_coords?.lat || 0, ticketData.center_coords?.long || 0]);
                }
            } catch (error) {
                setRetry(prevRetry => prevRetry + 1);
                setIsLoading(false);
            }
        };
        if (data == null && retry === 0) {
            fetchData();
        }
    }, [id]);

    useEffect(() => {
        if (!data) return;
        const report = reportsArray[current].data
        setReportId(report?.id || "");
        setReportDate(report.report_date || "");
        // Precipitation data and total calculation
        const precipitation = report?.weather_info?.precipitation || [];
        setPrecipitationData(precipitation);
        // Calculate total precipitation and update the state
        const total = calculateTotal(precipitation);
        setTotalPrecipitation(total);
        // Set other state variables
        setProductivityDates(report?.reference_graph_data.point_dates || []);
        setAvgProductivity(report?.reference_graph_data.avg_productivity || []);
        setHighProductivity(report?.reference_graph_data.high_productivity || []);
        setLowProductivity(report?.reference_graph_data.low_productivity || []);
        setNdviData(report?.plant_health?.plant_health_graph_data || []);
        setMaxWindSpeed(report?.weather_info?.parameters_info?.max_windspeed || "");
        setAvgTempData(report?.weather_info?.parameters_info?.avg_temp || "");
        setMaxTempData(report?.weather_info?.parameters_info?.max_temp || "");
        setMinTempData(report?.weather_info?.parameters_info?.min_temp || "");
        setMapBounds(report?.true_image_data?.bbox || [])
        setPrecipitationDate(report?.weather_info?.dates || []);
        setZarcCompliance(report?.zarc_compliance || []);
        setEstimatedSowingDate(report?.plant_health.estimated_sowing_date || "");
        setEstimatedHarvestDate(report?.plant_health.estimated_harvest_date || "");
        setNotifications(report?.notifications || []);
        setAtypicalEvents(report?.atypical_events || []);
        setSowedAreaPercentage(report?.area_usage?.sowed_area_percentage);
        setMapboxToken(report.mapbox_token)
        setHarvestMonitoringComposite(report?.harvest_monitoring_composite?.tiff_url || "");
        setTrueImageTiff(report?.true_image_tiff?.tiff_url || "");

        const harvestData = report?.harvest_monitoring_data;
        const validFeatures = harvestData?.features?.map((feature) => ({
            type: "Feature", ...feature,
        })) || [];
        setGeometryCoords({
            type: "FeatureCollection", features: validFeatures,
        });
    }, [current]);

    const handleReportDownload = async () => {
        if (!reportId) {
            console.error("Report ID is not available");
            return;
        }
        try {
            const {blob, headers} = await fetchTrueImageDownload(reportId);
            const contentDisposition = headers.get('content-disposition');
            let fileName = 'download.tiff';
            if (contentDisposition) {
                const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
                if (fileNameMatch && fileNameMatch.length === 2) {
                    fileName = fileNameMatch[1];
                }
            }
            const downloadUrl = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(downloadUrl);
        } catch (error) {
            console.error("Error downloading file:", error);
        }
    };

    async function convertGeoTIFFToImageURL(geoTIFFUrl) {
        const response = await fetch(geoTIFFUrl);
        const arrayBuffer = await response.arrayBuffer();
        const tiff = await GeoTIFF.fromArrayBuffer(arrayBuffer);
        const image = await tiff.getImage();

        const width = image.fileDirectory.ImageWidth;
        const height = image.fileDirectory.ImageLength;

        if (!Number.isInteger(width) || !Number.isInteger(height) || width <= 0 || height <= 0) {
            throw new Error(`Invalid image dimensions: width=${width}, height=${height}`);
        }

        const raster = await image.readRasters();

        const bounds = image.getBoundingBox();
        setGeoTiffBounds([
            [bounds[0], bounds[3]], // Bottom-left
            [bounds[2], bounds[3]], // Bottom-right
            [bounds[2], bounds[1]], // Top-right
            [bounds[0], bounds[1]], // Top-left
        ]);
        const canvas = document.createElement('canvas');
        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext('2d');
        const imageData = ctx.createImageData(width, height);

        for (let i = 0; i < raster[0].length; i++) {
            const red = raster[0][i] || 0;
            const green = raster[1] ? raster[1][i] : red;
            const blue = raster[2] ? raster[2][i] : red;

            imageData.data[i * 4] = red;
            imageData.data[i * 4 + 1] = green;
            imageData.data[i * 4 + 2] = blue;
            imageData.data[i * 4 + 3] = (red || green || blue) && 255;
        }

        ctx.putImageData(imageData, 0, 0);

        return canvas.toDataURL('image/png');
    }

    async function convertTrueImageTIFFToImageURL(geoTIFFUrl) {
        const response = await fetch(geoTIFFUrl);
        const arrayBuffer = await response.arrayBuffer();
        const tiff = await GeoTIFF.fromArrayBuffer(arrayBuffer);
        const image = await tiff.getImage();

        const width = image.fileDirectory.ImageWidth;
        const height = image.fileDirectory.ImageLength;

        if (!Number.isInteger(width) || !Number.isInteger(height) || width <= 0 || height <= 0) {
            throw new Error(`Invalid image dimensions: width=${width}, height=${height}`);
        }

        const raster = await image.readRasters();

        const bounds = image.getBoundingBox();
        setTrueImageBounds([
            [bounds[0], bounds[3]], // Bottom-left
            [bounds[2], bounds[3]], // Bottom-right
            [bounds[2], bounds[1]], // Top-right
            [bounds[0], bounds[1]], // Top-left
        ]);

        const xRes = (bounds[2] - bounds[0]) / width;
        const yRes = (bounds[3] - bounds[1]) / height;

        const polygon = convertToGeoJSON(kmlGeometry);
        
        const canvas = document.createElement('canvas');
        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext('2d');
        const imageData = ctx.createImageData(width, height);

        for (let y = 0; y < height; y++) {
            for (let x = 0; x < width; x++) {
                const index = y * width + x;

                const lng = bounds[0] + x * xRes;
                const lat = bounds[3] - y * yRes;

                const point = { type: "Point", coordinates: [lng, lat] };
                const insidePolygon = booleanPointInPolygon(point, polygon);

                const red = raster[0][index] || 0;
                const green = raster[1][index] || 0;
                const blue = raster[2][index] || 0;

                imageData.data[index * 4] = red;
                imageData.data[index * 4 + 1] = green;
                imageData.data[index * 4 + 2] = blue;
                imageData.data[index * 4 + 3] = insidePolygon ? 255 : 0;
            }
        }

        ctx.putImageData(imageData, 0, 0);

        return canvas.toDataURL('image/png');
    }

    const getDynamicZoom = (area) => {
        if (area <= 15) return 15;
        if (area <= 100) return 14;
        return 13;
    };

    useEffect(() => {
        const loadImage = async () => {
            if (!harvestMonitoringComposite) return;

            try {
                const imageUrl = await convertGeoTIFFToImageURL(harvestMonitoringComposite);
                setImageURL(imageUrl);
            } catch (error) {
                console.error('Error processing GeoTIFF:', error);
            }
        };

        loadImage();
    }, [harvestMonitoringComposite]);

    useEffect(() => {
        const loadTrueImage = async () => {
            if (!trueImageTiff || !imageURL) return;

            try {
                const trueImageUrl = await convertTrueImageTIFFToImageURL(trueImageTiff);
                setTrueImageURL(trueImageUrl);
            } catch (error) {
                console.error('Error processing True Image:', error);
            }
        };
        loadTrueImage();
    }, [trueImageTiff, imageURL]);

    useEffect(() => {
        if (!ndviData || ndviData.length === 0) {
            return;
        }
        // **Process NDVI Data**
        let processedNdviData = ndviData
            .filter(item => item && item.date && !isNaN(new Date(item.date)))
            .map(item => ({
                ...item,
                dateObj: new Date(item.date),
            }))
            .filter(item => item.avg_value !== 0);
        processedNdviData.sort((a, b) => a.dateObj - b.dateObj);

        // Filter NDVI data
        const ndviFilteredData = [];
        const ndviTimeThreshold = 7 * 24 * 60 * 60 * 1000; // 7 days in milliseconds
        let ndviPreviousDate = null;

        for (let i = processedNdviData.length - 1; i >= 0; i--) {
            const currentDataPoint = processedNdviData[i];
            const currentDate = currentDataPoint.dateObj;

            if (
                ndviPreviousDate === null ||
                ndviPreviousDate - currentDate >= ndviTimeThreshold
            ) {
                ndviFilteredData.push(currentDataPoint);
                ndviPreviousDate = currentDate;
            }
        }
        ndviFilteredData.reverse();

        const ndviValuesArray = ndviFilteredData.map(item => item.avg_value);
        const ndviDatesArray = ndviFilteredData.map(item => item.dateObj.getTime());

        // **Prepare Report Date Chart**
        let reportDateChart = null;
        if (reportDate) {
            const reportDateObj = new Date(reportDate);
            if (!isNaN(reportDateObj.getTime())) {
                reportDateObj.setDate(reportDateObj.getDate() - 15);
                reportDateChart = reportDateObj.toISOString().split('T')[0];
            } else {
                console.error('Invalid reportDate:', reportDate);
            }
        } else {
            console.error('reportDate is null or undefined');
        }

        if (selectedChart === 'growthCurve') {
            // **Prepare Annotations**
            const annotations = [];

            // Estimated Sowing Date Annotation
            if (estimatedSowingDate) {
                annotations.push({
                    x: new Date(estimatedSowingDate).getTime(), borderColor: "#12c2c1", label: {
                        borderColor: "#12c2c1", style: {
                            fontFamily: 'Outfit', fontSize: '12px', color: "#ffffff", background: "#12c2c1",
                        }, text: "Data de Plantio",
                    },
                });
            }

            // Estimated Harvest Date Annotation
            if (estimatedHarvestDate) {
                annotations.push({
                    x: new Date(estimatedHarvestDate).getTime(), borderColor: "#fa8b16", label: {
                        borderColor: "#fa8b16", style: {
                            fontFamily: 'Outfit', fontSize: '12px', color: "#ffffff", background: "#fa8b16",
                        }, text: "Data de Colheita",
                    },
                });
            }

            // Report Date Chart Annotation
            if (reportDateChart) {
                annotations.push({
                    x: new Date(reportDateChart).getTime(), borderColor: "#1791ff", label: {
                        borderColor: "#1791ff", style: {
                            fontFamily: 'Outfit', fontSize: '12px', color: "#ffffff", background: "#1791ff",
                        }, text: "Início do Monitoramento",
                    },
                });
            }

            // **Update Chart Data for Growth Curve**
            setChartData({
                series: [{
                    name: 'NDVI', data: ndviValuesArray,
                },], options: {
                    stroke: {
                        width: 4, dashArray: 0,
                    }, chart: {
                        id: 'avg-ndvi-chart', toolbar: {
                            show: false,
                        }, zoom: {
                            enabled: false,
                        },
                    }, plotOptions: {
                        bar: {
                            columnWidth: '70%',
                        },
                    }, title: {
                        text: "Curva de crescimento", align: "left",
                    }, dataLabels: {
                        enabled: false,
                    }, xaxis: {
                        type: 'datetime', categories: ndviDatesArray, labels: {
                            show: true, datetimeUTC: false, formatter: function (value) {
                                const date = new Date(value);
                                const day = String(date.getDate()).padStart(2, '0');
                                const month = String(date.getMonth() + 1).padStart(2, '0');
                                const year = date.getFullYear();
                                return `${day}/${month}/${year}`;
                            },
                        },
                    }, yaxis: {
                        min: 0.0, max: 1.0, tickAmount: 8, labels: {
                            formatter: (value) => value.toFixed(1),
                        },
                    }, grid: {
                        borderColor: '#90A4AE', strokeDashArray: 3,
                    }, tooltip: {
                        x: {
                            formatter: function (value, {dataPointIndex}) {
                                const timestamp = ndviDatesArray[dataPointIndex];
                                const date = new Date(timestamp);
                                const day = String(date.getDate()).padStart(2, '0');
                                const month = String(date.getMonth() + 1).padStart(2, '0');
                                const year = date.getFullYear();
                                return `${day}/${month}/${year}`;
                            },
                        },
                        y: {
                            formatter: function (val) {
                                return `${val}`;
                            },
                        },
                    }, colors: ['#6bcd3d'], annotations: {
                        xaxis: annotations,
                    },
                },
            });
        } else if (selectedChart === 'productivityCurve') {
            // **Align Productivity Data with NDVI Dates**
            // Determine the number of data points to use
            const length = ndviValuesArray.length;
            if (highProductivity.length >= length && avgProductivity.length >= length && lowProductivity.length >= length) {
                // Slice the productivity arrays to match the length of ndviValuesArray
                const highProductivityArray = highProductivity.slice(0, length);
                const avgProductivityArray = avgProductivity.slice(0, length);
                const lowProductivityArray = lowProductivity.slice(0, length);

                // **Update Chart Data**
                setChartData({
                    series: [{
                        name: 'NDVI', data: ndviValuesArray,
                    }, {
                        name: '60+ Sa/Ha', data: lowProductivityArray,
                    }, {
                        name: '35 Sa/Ha', data: avgProductivityArray,
                    }, {
                        name: '15 Sa/Ha', data: highProductivityArray,
                    },], options: {
                        chart: {
                            id: 'productivity-chart', toolbar: {
                                show: false,
                            }, zoom: {
                                enabled: false,
                            },
                        }, plotOptions: {
                            bar: {
                                columnWidth: '70%',
                            },
                        }, legend: {
                            onItemClick: {
                                toggleDataSeries: false,
                            }, onItemHover: {
                                highlightDataSeries: false,
                            },
                        }, title: {
                            text: "Estimativa de produtividade", align: "left",
                        }, colors: ['#6bcd3d', '#b2e2ff', '#ffd577', '#ff8184'], stroke: {
                            width: [4, 4, 4, 4], dashArray: [0, 6, 6, 6],
                        }, dataLabels: {
                            enabled: false,
                        }, xaxis: {
                            type: 'datetime', categories: ndviDatesArray, labels: {
                                show: false, datetimeUTC: false, formatter: function (value) {

                                },
                            },
                        }, yaxis: {
                            min: 0.0, max: 1.0, tickAmount: 8, labels: {
                                formatter: (value) => value.toFixed(1),
                            },
                        }, grid: {
                            borderColor: '#90A4AE', strokeDashArray: 3,
                        }, tooltip: {
                            x: {
                                formatter: function (value) {
                                    const date = new Date(value);
                                    const day = String(date.getDate()).padStart(2, '0');
                                    const month = String(date.getMonth() + 1).padStart(2, '0');
                                    const year = date.getFullYear();
                                    return `${day}/${month}/${year}`;
                                },
                            },
                            y: {
                                formatter: function (val) {
                                    return val !== null ? `${val.toFixed(2)}` : 'N/D';
                                },
                            },
                        },
                    },
                });
            } else {
                console.error('Not enough productivity data to match NDVI data length.');
            }
        }
    }, [ndviData, selectedChart, highProductivity, avgProductivity, lowProductivity, reportDate]);

    const handleRadioChange = (e) => {
        setSelectedMap(e.target.value);
        setViewport((prev) => ({
            ...prev,
            longitude: data?.center_coords?.long,
            latitude: data?.center_coords?.lat,
            zoom: getDynamicZoom(data?.land_size.hectares),
        }));
    };

    const handleSegmentedChange = (value) => {
        setSelectedChart(value);
    };

    const calculateTotal = (dataArray) => {
        if (!dataArray || dataArray.length === 0) return 0;
        return dataArray.reduce((acc, value) => acc + value, 0);
    };

    function convertToGeoJSON(polygonString) {
        const coordinatesString = polygonString.replace('SRID=4326;POLYGON ((', '').replace('))', '');
        const coordinatesArray = coordinatesString.split(', ').map(coord => {
            const [lng, lat] = coord.split(' ').map(Number);
            return [lng, lat];
        });

        const response = {
            type: "Feature",
            geometry: {
                type: "Polygon",
                coordinates: [coordinatesArray]
            },
            properties: {}
        };

        return response;
    }

    if (isLoading) {
        return <LoadingPage/>;
    }

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const onChange = (value) => {
        setCurrent(value);
    };

    const handleStepChange = (value) => {
        setCurrent(value);
    };

    const handlePreviousStep = () => {
        if (current < reportsArray.length - 1) handleStepChange(current + 1);
    };

    const handleNextStep = () => {
        if (current > 0) handleStepChange(current - 1);
    };

    const handleFirstStep = () => handleStepChange(reportsArray.length - 1);
    const handleLastStep = () => handleStepChange(0);

    const filteredReports = reportsArray.filter((_, index) => {
        const leftSteps = Math.max(1, 4 + current - reportsArray.length);
        const rightSteps = Math.max(1, 3 - current);

        const minIndex = Math.max(0, current - leftSteps);
        const maxIndex = Math.min(reportsArray.length - 1, current + rightSteps);

        return index === 0 || index === reportsArray.length - 1 || (index >= minIndex && index <= maxIndex);
    });

    const displayReports = filteredReports.map((item) => item); // No placeholders, include all reports

    const adjustedCurrent = displayReports.findIndex((item) => item.key === reportsArray[current]?.key);
    return (<div>
        <div
            style={{
                width: '100%', display: "inline-flex", flexDirection: "column", justifyContent: "space-between",
            }}
        >
            {data ? (<Row gutter={24} style={{
                alignItems: "center",
            }}>
                <Col className="gutter-row" span={2.5}>
                    <div className="item">
                        <p className="farmInfoTextTitleSenseBasic">ID do Cliente:</p>
                        <p className="farmInfoText ellipsisSenseBasic">
                            {data?.farm_data?.farmer?.farmer_name ? data.farm_data.farmer.farmer_name : ""}
                        </p>
                    </div>
                </Col>
                <Col className="gutter-row" span={2.5}>
                    <div className="item">
                        <p className="farmInfoTextTitle">Fazenda:</p>
                        <p className="farmInfoText">{data?.farm_data?.farm_name || ""}</p>
                    </div>
                </Col>
                <Col className="gutter-row" span={2.5}>
                    <div className="item">
                        <p className="farmInfoTextTitle">Talhão:</p>
                        <p className="farmInfoText">
                            {data?.farm_data?.field_name || ""}
                        </p>
                    </div>
                </Col>
                <Col className="gutter-row" span={2.5}>
                    <div className="item">
                        <p className="farmInfoTextTitle">ID da Proposta:</p>
                        <p className="farmInfoText">{data?.farm_data?.proposal_id || ""}</p>
                    </div>
                </Col>
                <Col className="gutter-row" span={2.5}>
                    <div className="item">
                        <p className="farmInfoTextTitle">Local:</p>
                        <p className="farmInfoText">
                            {`${data?.farm_data?.kml_document?.kml_polygon?.municipality}, ${data?.farm_data?.kml_document?.kml_polygon?.state}`}
                        </p>
                    </div>
                </Col>
                <Col className="gutter-row" span={2.5}>
                    <div className="item">
                        <p className="farmInfoTextTitle">Cultura:</p>
                        <p className="farmInfoText">{data.farm_data?.crop_type}</p>
                    </div>
                </Col>
                <Col className="gutter-row" span={2.5}>
                    <div className="item">
                        <p className="farmInfoTextTitle">Status:</p>
                        <p className="farmInfoText" style={{color: "#1890FF"}}>
                            Sense
                        </p>
                    </div>
                </Col>
                <Col className="gutter-row" span={2.5}>
                    <div className="item">
                        <p className="farmInfoTextTitle">Usuário:</p>
                        <p className="farmInfoText ellipsisSenseBasic" style={{color: "#B64CFF"}}>
                            {data.subwriter || ""}
                        </p>
                    </div>
                </Col>
                <Col className="gutter-row" span={2.5}>
                    <div className="item">
                        <p className="farmInfoTextTitle">Gerado em:</p>
                        <p className="farmInfoText">{formattedDate}</p>
                    </div>
                </Col>
                <Col
                    style={{
                        display: "flex", justifyContent: "flex-end", flex: 1,
                    }}
                >
                    <Tooltip
                        title={isButtonDisabled ? "Disponível ao fim do monitoramento. " : ""}
                    >
                        <Button
                            className="pdfButtonSenseBasic"
                            type="primary"
                            icon={<PrinterOutlined/>}
                            disabled={isButtonDisabled}
                            style={{
                                marginRight: "20px",
                            }}
                        >
                            Gerar Laudo
                        </Button>
                    </Tooltip>
                </Col>
            </Row>) : null}
        </div>
        <Divider/>
        <div
            style={{
                height: "100%", position: "auto", overflow: "hidden"
            }}
        >
            <Row>
                <Col span={17}>
                    <div
                        style={{
                            height: "650px",
                            padding: "10px",
                            position: "relative",
                            width: "100%",
                            borderRadius: token.borderRadiusLG,
                            background: token.colorFillAlter,
                            border: `1px solid ${token.colorBorderSecondary}`,
                        }}
                    >
                        <div className="senseBasicLegendCard">
                            <div className="senseBasicButtons">
                                <Flex vertical gap="middle">
                                    <Radio.Group defaultValue="plantedArea" buttonStyle="solid"
                                                 onChange={handleRadioChange}>
                                        <Radio.Button disabled={!trueImageTiff}
                                                      value="trueImage">{trueImageTiff ? "Imagem True Color" : "Gerando True Color"}</Radio.Button>
                                        <Radio.Button value="plantedArea">Área Plantada</Radio.Button>
                                    </Radio.Group>
                                </Flex>
                            </div>
                            {selectedMap === 'plantedArea' && geometryCoords && (<div className="senseBasicLegends">
                                <div className="senseBasicLegendContent">
                                    <div className="senseBasicLegendTitle">
                                        Legendas
                                    </div>
                                    <div className="senseBasicMapLegendLine">
                                        <div
                                            className="senseBasicMapLegendSquare"
                                            style={{backgroundColor: "#58C058"}}
                                        />
                                        <div className="senseBasicLegendText">Área plantada</div>
                                    </div>
                                    <div className="senseBasicMapLegendLine">
                                        <div
                                            className="senseBasicMapLegendSquare"
                                            style={{backgroundColor: "#FF4966"}}
                                        />
                                        <div className="senseBasicLegendText">Área não plantada</div>
                                    </div>
                                </div>
                            </div>)}
                            {selectedMap === 'trueImage' && (
                                <div className="senseBasicTrueImageDisclaimer">
                                    Quando identificamos uma alta incidência de nuvens, nosso modelo de IA remove nuvens
                                    das imagens utilizando uma série temporal.
                                    Por isso, a imagem resultante pode não representar com precisão o estado atual do
                                    talhão.
                                </div>
                            )}
                            <div className="senseBasicDownloadReportContainer">
                                <Button
                                    className="senseBasicDownloadReportButton"
                                    type="primary"
                                    icon={<DownloadOutlined/>}
                                    onClick={handleReportDownload}
                                >
                                    Baixar imagem
                                </Button>
                            </div>
                        </div>
                        <div className="senseBasicReportSelectorCard">
                            <div style={{display: "flex", flexDirection: "row", gap: 8}}>
                                <DoubleLeftOutlined
                                    className="DoubleLeftOutlined"
                                    onClick={handleFirstStep}
                                    disabled={current === 0}
                                />
                                <LeftOutlined
                                    className="LeftOutlined"
                                    onClick={handlePreviousStep}
                                    disabled={current === 0}
                                />
                            </div>
                            <div className="custom-scroll-container">
                                <Steps
                                    className="custom-steps"
                                    type="inline"
                                    current={adjustedCurrent}
                                    onChange={onChange}
                                    items={displayReports.map((item) => ({
                                        title: item.label,
                                        key: item.key,
                                    }))}
                                />
                            </div>
                            <div style={{display: "flex", flexDirection: "row", gap: 8}}>
                                <RightOutlined
                                    className="RightOutlined"
                                    onClick={handleNextStep}
                                    disabled={current === reportsArray.length - 1}
                                />
                                <DoubleRightOutlined
                                    className="DoubleRightOutlined"
                                    onClick={handleLastStep}
                                    disabled={current === reportsArray.length - 1}
                                />
                            </div>
                        </div>
                        {mapboxToken && geometryCoords && kmlGeometry ? (<Map
                            {...viewport}
                            mapboxAccessToken={mapboxToken}
                            style={{width: "100%", height: "100%"}}
                            mapStyle="mapbox://styles/mapbox/satellite-v9"
                            onMove={(event) => setViewport(event.viewState)}
                            attributionControl={false}
                            dragRotate={false}
                            pitchWithRotate={false}
                        >
                            {kmlGeometry && (<Source type="geojson" data={convertToGeoJSON(kmlGeometry)}>
                                <Layer
                                    {...polygonStylePatterns.mapboxFieldAreaBorder}
                                    id="kml-layer"
                                />
                            </Source>)}

                            {geoTiffBounds && harvestMonitoringComposite && selectedMap === "plantedArea" && (
                                <>
                                    <Source
                                        id="geotiff-source"
                                        type="image"
                                        url={imageURL}
                                        coordinates={geoTiffBounds}
                                    />
                                    <Layer
                                        id="harvest-monitoring-layer"
                                        type="raster"
                                        beforeId="kml-layer"
                                        source="geotiff-source"
                                    />
                                </>
                            )}

                            {trueImageBounds && trueImageURL && selectedMap === "trueImage" && (
                                <>
                                    <Source
                                        id="true-image-source"
                                        type="image"
                                        url={trueImageURL}
                                        coordinates={trueImageBounds}
                                    />
                                    <Layer
                                        id="true-image-layer"
                                        type="raster"
                                        beforeId="kml-layer"
                                        source="true-image-source"
                                    />
                                </>
                            )}

                        </Map>) : (<div style={{textAlign: "center", padding: "200px 200px"}}>
                            <div style={{textAlign: 'center', padding: '20px'}}>
                                Carregando o mapa, por favor aguarde...
                            </div>
                            <br/>
                            <Spin
                                indicator={<LoadingOutlined
                                    style={{fontSize: 68, color: "#B64CFF"}}
                                    spin
                                />}
                            />
                        </div>)}
                    </div>
                    <Content>
                        <div
                            style={{
                                width: '100%', padding: '10px',
                            }}
                        >
                            <Row>
                                <Col span={24}>
                                    <div className="evolutionChartContainer">
                                        <Row justify="space-between">
                                            <div className="evolutionChartTitle">Gráfico de desenvolvimento</div>
                                            <div className="evolutionChartButtons">
                                                <Flex vertical gap="end">
                                                    {<Segmented
                                                        options={[{
                                                            label: 'Curva de crescimento', value: 'growthCurve'
                                                        },

                                                            {
                                                                label: (<Tooltip
                                                                    title="Análise disponível após desenvolvimento da cultura."
                                                                    onItemHover={true || (!["soja", "milho"].includes(data.farm_data?.crop_type))} // Controla a visibilidade
                                                                >
                                                                    <span>Estimativa de produtividade</span>
                                                                </Tooltip>),
                                                                value: 'productivityCurve',
                                                                disabled: true || (!["soja", "milho"].includes(data.farm_data?.crop_type)),
                                                            }]}
                                                        defaultValue="growthCurve"
                                                        onChange={handleSegmentedChange}
                                                    />}
                                                </Flex>
                                            </div>
                                        </Row>
                                        {senseParamsData && chartData.series.length > 0 && !isLoading ? (
                                            <ReactApexChart
                                                options={chartData.options}
                                                series={chartData.series}
                                                type="line"
                                                height={350}
                                                width="100%"
                                                style={{overflow: 'hidden'}}
                                            />) : (<div style={{textAlign: "center", padding: "150px"}}>
                                            <div style={{textAlign: 'center', padding: '20px'}}>
                                                Carregando o gráfico, por favor aguarde...
                                            </div>
                                            <br/>
                                            <Spin
                                                indicator={<LoadingOutlined
                                                    style={{fontSize: 68, color: "#B64CFF"}}
                                                    spin
                                                />}
                                            />
                                        </div>)}
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Content>
                </Col>
                <Col span={7}>
                    <div
                        className="senseBasicSidePanel"
                        style={{
                            width: '100%', position: 'relative', padding: '10px',
                        }}
                    >
                        <div
                            style={{
                                position: "absolute", right: 10, cursor: "pointer",
                            }}
                        >
                        </div>
                        <Tabs defaultActiveKey="1" centered tabBarGutter={45}>
                            <TabPane tab="Alertas" key="1">
                                <Row>
                                    <Col span={24}>
                                        <AtypicalEvents
                                            notifications={atypicalEvents}
                                            esg_detections={data?.sicar_overlaps}
                                        />
                                    </Col>
                                </Row>
                                <Divider/>
                                <Row>
                                    <Col span={24}>
                                        <PlantingWindow
                                            zarcCompliance={zarcCompliance}
                                            dateHarvest={estimatedHarvestDate}
                                            dateSowing={estimatedSowingDate}
                                        />
                                    </Col>
                                </Row>
                                <Divider/>

                                <Row>
                                    <Col span={24}>
                                        <Notifications notifications={notifications}/>
                                    </Col>
                                </Row>
                            </TabPane>

                            <TabPane tab="Dados Quinzenais" key="2">
                                <Row
                                    style={{
                                        padding: "10px",
                                        borderRadius: token.borderRadiusLG,
                                        border: `1px solid ${token.colorBorderSecondary}`,
                                    }}
                                >
                                    <Col span={24}>
                                        <Title
                                            style={{
                                                lineHeight: "4px",
                                                fontSize: "32px",
                                                color: "#   ",
                                                padding: "10px",
                                                paddingTop: "0px",
                                            }}
                                        >
                                            Resumo Climático
                                        </Title>
                                        <Divider/>
                                        <Row>
                                            <Col>
                                                <div className="infoSectionRainfallSum">
                                                    <CloudOutlined className="cloudIconLarge"/>
                                                    <div>
                                                        <Text className="textInfoTitle">
                                                            Total de chuva dos últimos 15 dias
                                                        </Text>
                                                        <br/>
                                                        {typeof totalPrecipitation === 'number' ? `${totalPrecipitation.toFixed(2)} mm` : 'Indisponível'}
                                                    </div>
                                                </div>
                                                <div
                                                    className="infoSectionMaxTemp"
                                                    style={{paddingTop: "20px"}}
                                                >
                                                    <img
                                                        src="/icons/brightness_5.svg"
                                                        alt="Cloud Icon"
                                                        width={45}
                                                        height={45}
                                                    />
                                                    <div>
                                                        <Text className="textInfoTitle">
                                                            Temperatura máxima dos últimos 15 dias
                                                        </Text>
                                                        <br/>
                                                        {typeof maxTempData === 'number' ? `${maxTempData.toFixed(2)} ºC` : 'Indisponível'}
                                                    </div>
                                                </div>

                                                <div
                                                    className="infoSectionAvgTemp"
                                                    style={{paddingTop: "20px"}}
                                                >
                                                    <img
                                                        src="/icons/device_thermostat.svg"
                                                        alt="Cloud Icon"
                                                        width={45}
                                                        height={45}
                                                    />
                                                    <div>
                                                        <Text className="textInfoTitle">
                                                            Temperatura média dos últimos 15 dias
                                                        </Text>
                                                        <br/>
                                                        {typeof avgTempData === 'number' ? `${avgTempData.toFixed(2)} ºC` : 'Indisponível'}
                                                    </div>
                                                </div>
                                                <div
                                                    className="infoSectionMinTemp"
                                                    style={{paddingTop: "20px"}}
                                                >
                                                    <img
                                                        src="/icons/ac_unit.svg"
                                                        alt="Cloud Icon"
                                                        width={45}
                                                        height={45}
                                                    />
                                                    <div>
                                                        <Text className="textInfoTitle">
                                                            Temperatura mínima dos últimos 15 dias
                                                        </Text>
                                                        <br/>
                                                        {typeof minTempData === 'number' ? `${minTempData.toFixed(2)} ºC` : 'Indisponível'}
                                                    </div>
                                                </div>

                                                <div
                                                    className="infoSectionMaxWind "
                                                    style={{paddingTop: "20px"}}
                                                >
                                                    <img
                                                        src="/icons/wind_power.svg"
                                                        alt="Cloud Icon"
                                                        width={45}
                                                        height={45}
                                                    />
                                                    <div>
                                                        <Text className="textInfoTitle">
                                                            Velocidade máxima do vento
                                                        </Text>
                                                        <br/>
                                                        {typeof maxWindSpeed === 'number' ? `${maxWindSpeed.toFixed(2)} km/h` : 'Indísponivel'}
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row
                                    style={{
                                        marginTop: "15px",
                                        padding: "10px",
                                        borderRadius: token.borderRadiusLG,
                                        border: `1px solid ${token.colorBorderSecondary}`,
                                    }}
                                >
                                    <Col span={24}>
                                        <Title
                                            style={{
                                                lineHeight: "4px",
                                                fontSize: "32px",
                                                color: "#   ",
                                                padding: "10px",
                                                paddingTop: "0px",
                                            }}
                                        >
                                            Uso da área
                                        </Title>

                                        <Divider/>

                                        <Row
                                            style={{
                                                padding: "10px",
                                                borderRadius: token.borderRadiusLG,
                                                border: `1px solid ${token.colorBorderSecondary}`,
                                            }}
                                        >
                                            <Col span={24}>
                                                <div className="infoSection">
                                                    <Compare style={{fontSize: 48, color: "#00000040"}}/>
                                                    <div>
                                                        <Text className="textInfoTitle">
                                                            Área utilizada para plantio
                                                        </Text>
                                                        <br/>
                                                        <Text className="textInfo">
                                                            {typeof sowedAreaPercentage === 'number' ? `${sowedAreaPercentage.toFixed(2)}% da área do talhão` : 'Indisponível'}
                                                        </Text>
                                                    </div>
                                                </div>
                                                <div
                                                    className="infoSection"
                                                    style={{paddingTop: "15px"}}
                                                >
                                                    <YardOutlined style={{fontSize: 48, color: "#00000040"}}/>
                                                    <div>
                                                        <Text className="textInfoTitle">
                                                            Cultura identificada
                                                        </Text>
                                                        <br/>
                                                        {/*Once there is no way to identify the culture, remains hardcoded as "cultura não identificada*/}
                                                        <Text className="textInfo">Não identificada</Text>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Divider/>
                                <Button
                                    className="precipitationChartButtonSenseBasic"
                                    type="primary"
                                    icon={<MenuFoldOutlined/>}
                                    onClick={showModal}
                                >
                                    Visualizar gráfico de precipitação
                                </Button>
                                <Modal
                                    open={isModalOpen}
                                    onCancel={handleCancel}
                                    footer={null}
                                    width="60%"
                                >
                                    <p>
                                        <div>
                                            <Row>
                                                <Col span={24}>
                                                    <div>
                                                        <PrecipitationChart
                                                            datesArray={precipitationDate}
                                                            valuesArray={precipitationData}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </p>
                                </Modal>
                            </TabPane>
                            <TabPane tab="ESG" key="3">
                                <div
                                    style={{
                                        maxHeight: "1000px", overflowY: "auto", paddingBotton: "10px"
                                    }}
                                >
                                    <ESGCardsList
                                        data={data?.risk_classification_records}
                                        isLoading={data?.risk_score ? data?.risk_score : null}
                                        sicar_overlaps={data?.sicar_overlaps}
                                    />
                                </div>
                            </TabPane>
                        </Tabs>
                    </div>
                </Col>
            </Row>
        </div>
    </div>);
}

export default SenseBasicReport;