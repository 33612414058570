const atypicalEventsContent = {
  SEVEN_DAY_NO_RAIN: {
    title: "Período de seca",
    dateText: (date) => `${date}`,
    description:
      "Seca na região, ausência de chuva em um período maior do que 7 dias.",
    recommendation:
      "Recomenda-se acompanhamento no talhão.",
    icon: "/icons/icon-events.svg",
    titleColor: "#FA8C16",
  },
  DAILY_HIGH: {
    title: "Chuva acima do ideal",
    dateText: (date) => `${date}`,
    description: "Chuva acima de 80mm em um período de 24h na região.",
    recommendation:
      "Recomenda-se acompanhamento no talhão.",
    icon: "/icons/icon-events.svg",
    titleColor: "#FA8C16",
  },
  DAILY_VERY_HIGH: {
    title: "Chuva intensa",
    dateText: (date) => `${date}`,
    description:
      "Chuva intensa na região, acima de 100mm em um período de 24h.",
    recommendation:
      "Recomenda-se acompanhamento no talhão.",
    icon: "/icons/icon-events.svg",
    titleColor: "#FA8C16",
  },
  THREE_DAY_HIGH: {
    title: "Chuva acima do ideal",
    dateText: (date) => `${date}`,
    description: "Chuva acima de 100mm em um período de 3 dias na região.",
    recommendation:
      "Recomenda-se acompanhamento no talhão.",
    icon: "/icons/icon-events.svg",
    titleColor: "#FA8C16",
  },
  THREE_DAY_VERY_HIGH: {
    title: "Chuva intensa",
    dateText: (date) => `${date}`,
    description:
      "Chuva intensa na região, acima de 110mm em um período de 3 dias.",
    recommendation:
      "Recomenda-se acompanhamento no talhão.",
    icon: "/icons/icon-events.svg",
    titleColor: "#FA8C16",
  },
  FIVE_DAY_HIGH: {
    title: "Chuva acima do ideal",
    dateText: (date) => `${date}`,
    description: "Chuva acima de 100mm em um período de 5 dias na região.",
    recommendation:
      "Recomenda-se acompanhamento no talhão.",
    icon: "/icons/icon-events.svg",
    titleColor: "#FA8C16",
  },
  FIVE_DAY_VERY_HIGH: {
    title: "Chuva intensa",
    dateText: (date) => `${date}`,
    description:
      "Chuva intensa na região, acima de 120mm em um período de 5 dias.",
    recommendation:
      "Recomenda-se acompanhamento no talhão.",
    icon: "/icons/icon-events.svg",
    titleColor: "#FA8C16",
  },

  HIGH_PRECIPITATION_5_DAYS: {
    title: "Chuva intensa",
    dateText: (date) => `${date}`,
    description:
      "Chuva intensa na região, acima de 50mm em um período de 5 dias.",
    recommendation:
      "Recomenda-se acompanhamento no talhão.",
    icon: "/icons/icon-events.svg",
    titleColor: "#FA8C16",
  },

  HIGH_PRECIPITATION_7_DAYS: {
    title: "Chuva intensa",
    dateText: (date) => `${date}`,
    description:
      "Chuva intensa na região, acima de 50mm em um período de 7 dias.",
    recommendation:
      "Recomenda-se acompanhamento no talhão.",
    icon: "/icons/icon-events.svg",
    titleColor: "#FA8C16",
  },

  HIGH_TEMP_5_DAYS: {
    title: "Temperatura acima do ideal",
    dateText: (date) => `${date}`,
    description:
      "Temperatura acima do ideal. Superior à 28ºC em um período de 5 dias.",
    recommendation:
      "Recomenda-se acompanhamento no talhão.",
    icon: "/icons/icon-events.svg",
    titleColor: "#FA8C16",
  },
  LOW_TEMP_3_DAYS: {
    title: "Temperatura abaixo do ideal",
    dateText: (date) => `${date}`,
    description:
      "Temperatura abaixo do ideal. Inferior à 10ºC em um período de 3 dias.",
    recommendation:
      "Recomenda-se acompanhamento no talhão.",
    icon: "/icons/icon-events.svg",
    titleColor: "#FA8C16",
  },
  LOW_TEMP_5_DAYS: {
    title: "Temperatura abaixo do ideal",
    dateText: (date) => `${date}`,
    description:
      "Temperatura abaixo do ideal. Inferior à 10ºC em um período de 5 dias.",
    recommendation:
      "Recomenda-se acompanhamento no talhão.",
    icon: "/icons/icon-events.svg",
    titleColor: "#FA8C16",
  },
  TEN_DAY_NO_RAIN: {
    title: "Período de Seca",
    dateText: (date) => `${date}`,
    description:
      "Seca na região, ausência de chuva em um período de 10 dias.",
    recommendation:
      "Recomenda-se acompanhamento no talhão.",
    icon: "/icons/icon-events.svg",
    titleColor: "#FA8C16",
  },

  ONE_DAY_TEMP_LOW_5_C: {
    title: "Baixa temperatura",
    dateText: (date) => `${date}`,
    description:
    "Foi verificado esta temperatura durante algum horário do dia, mas não necessariamente de maneira prolongada, recomenda-se conferir os gráficos de temperatura.",
    recommendation:
      "Recomenda-se acompanhamento no talhão.",
    icon: "/icons/icon-events.svg",
    titleColor: "#FA8C16",
  },
  ONE_DAY_TEMP_LOW_2_C: {
    title: "Risco de geada",
    dateText: (date) => `${date}`,
    description:
      "Temperatura inferior a 2ºC no período de 1 dia.",
    recommendation:
    "Foi verificado esta temperatura durante algum horário do dia, mas não necessariamente de maneira prolongada, recomenda-se conferir os gráficos de temperatura.",
    icon: "/icons/icon-events.svg",
    titleColor: "#FA8C16",
  },
  WIND_20_KM: {
    title: "Ventos fortes",
    dateText: (date) => `${date}`,
    description:
      "Vento acima de 20km em um período de 1 dia.",
    recommendation:
      "Recomenda-se acompanhamento no talhão para verificar possibilidade de perda na colheita.",
    icon: "/icons/icon-events.svg",
    titleColor: "#FA8C16",
  },
  WIND_50_KM: {
    title: "Ventos fortes",
    dateText: (date) => `${date}`,
    description:
      "Vento acima de 50km em um período de 1 dia.",
    recommendation:
      "Recomenda-se acompanhamento no talhão para verificar possibilidade de perda na colheita.",
    icon: "/icons/icon-events.svg",
    titleColor: "#FA8C16",
  },

  id_esg_1: {
    title: "Novo critério ESG identificado",
    dateText: () => "",
    description: "ESG foi identificada no talhão no mais recente relatório.",
    recommendation: "Detectado: APP",
    icon: "/icons/icon-redflag.svg",
    titleColor: "#F5222D",
    specialStyling: "",
  },
  id_esg_8: {
    title: "Novo critério ESG identificado",
    dateText: () => "",
    description: "ESG foi identificada no talhão no mais recente relatório.",
    recommendation: "Detectado: Banhado",
    icon: "/icons/icon-redflag.svg",
    titleColor: "#F5222D",
    specialStyling: "",
  },
  id_esg_11: {
    title: "Novo critério ESG identificado",
    dateText: () => "",
    description: "ESG foi identificada no talhão no mais recente relatório.",
    recommendation: "Detectado: Manguezal",
    icon: "/icons/icon-redflag.svg",
    titleColor: "#F5222D",
    specialStyling: "",
  },
  id_esg_13: {
    title: "Novo critério ESG identificado",
    dateText: () => "",
    description: "ESG foi identificada no talhão no mais recente relatório.",
    recommendation: "Detectado: Reserva Legal",
    icon: "/icons/icon-redflag.svg",
    titleColor: "#F5222D",
    specialStyling: "",
  },
  id_esg_14: {
    title: "Novo critério ESG identificado",
    dateText: () => "",
    description: "ESG foi identificada no talhão no mais recente relatório.",
    recommendation: "Detectado: Restinga",
    icon: "/icons/icon-redflag.svg",
    titleColor: "#F5222D",
    specialStyling: "",
  },
  id_esg_16: {
    title: "Novo critério ESG identificado",
    dateText: () => "",
    description: "ESG foi identificada no talhão no mais recente relatório.",
    recommendation: "Detectado: Uso Restrito",
    icon: "/icons/icon-redflag.svg",
    titleColor: "#F5222D",
    specialStyling: "",
  },
  id_esg_17: {
    title: "Novo critério ESG identificado",
    dateText: () => "",
    description: "ESG foi identificada no talhão no mais recente relatório.",
    recommendation: "Detectado: Vegetação Nativa",
    icon: "/icons/icon-redflag.svg",
    titleColor: "#F5222D",
    specialStyling: "",
  },
  id_esg_19: {
    title: "Novo critério ESG identificado",
    dateText: () => "",
    description: "ESG foi identificada no talhão no mais recente relatório.",
    recommendation: "Detectado: PRODES",
    icon: "/icons/icon-redflag.svg",
    titleColor: "#F5222D",
    specialStyling: "",
  },
  id_esg_20: {
    title: "Novo critério ESG identificado",
    dateText: () => "",
    description: "ESG foi identificada no talhão no mais recente relatório.",
    recommendation: "Detectado: Embargo ICMBIO",
    icon: "/icons/icon-redflag.svg",
    titleColor: "#F5222D",
    specialStyling: "",
  },
  id_esg_21: {
    title: "Novo critério ESG identificado",
    dateText: () => "",
    description: "ESG foi identificada no talhão no mais recente relatório.",
    recommendation: "Detectado: Embargo IBAMA",
    icon: "/icons/icon-redflag.svg",
    titleColor: "#F5222D",
    specialStyling: "",
  },
  id_esg_22: {
    title: "Novo critério ESG identificado",
    dateText: () => "",
    description: "ESG foi identificada no talhão no mais recente relatório.",
    recommendation: "Detectado: Assentamento",
    icon: "/icons/icon-redflag.svg",
    titleColor: "#F5222D",
    specialStyling: "",
  },
  id_esg_23: {
    title: "Novo critério ESG identificado",
    dateText: () => "",
    description: "ESG foi identificada no talhão no mais recente relatório.",
    recommendation: "Detectado: Área indígena",
    icon: "/icons/icon-redflag.svg",
    titleColor: "#F5222D",
    specialStyling: "",
  },
  id_esg_24: {
    title: "Novo critério ESG identificado",
    dateText: () => "",
    description: "ESG foi identificada no talhão no mais recente relatório.",
    recommendation: "Detectado: Sítio arqueológico",
    icon: "/icons/icon-redflag.svg",
    titleColor: "#F5222D",
    specialStyling: "",
  },

  default: {
    title: "Sem eventos atípicos!",
    dateText: () => "",
    description: "Não foi detectado nenhum evento atípico notório na região.",
    recommendation: "",
    icon: "/icons/icon-smile.svg",
    titleColor: "#52C41A",
    specialStyling: "column",
    specialMargingTop: "36px",
  },
};

export default atypicalEventsContent;
