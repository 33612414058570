import React from "react";
import { Row, Col, Typography } from "antd";
import { Flex, Progress } from "antd";

  const ClimaticRisk = ({ data }) => {
    const { Text } = Typography;
    const mapSnowIntensity = (intensity) => {
        const mapping = {
            1: "Fraca",
            2: "Moderada",
            3: "Forte",
        };
        return mapping[intensity] || "Indisponível";
    };
    const RiskProgress = ({ steps }) => {
      const options = {
          1: {
              strokeColors: ["#52C41A"],
              progress: 33,
              text: "Baixo",
          },
          2: {
              strokeColors: ["#FAAD14", "#FAAD14"],
              progress: 66,
              text: "Moderado",
          },
          3: {
              strokeColors: ["#FF4D4F", "#FF4D4F", "#FF4D4F",],
              progress: 99,
              text: "Alto",
          },
          default: {
            strokeColors: ["#D9D9D9"],
            progress: 0,
            text: "Indisponível",
          },
      };

      const selectedOption = options[steps] || options.default;

      return (
        <Progress
          percent={selectedOption.progress}
          steps={3}
          strokeColor={selectedOption.strokeColors}
          format={() => (selectedOption.text)}
        />
      );
  };

  const SnowRiskProgress = ({ steps }) => {
    const options = {
        1: {
            strokeColors: ["#52C41A"],
            progress: 33,
            text: "Fraca"
        },
        2: {
            strokeColors: ["#FAAD14", "#FAAD14"],
            progress: 66,
            text: "Moderada"
        },
        3: {
            strokeColors: ["#FF4D4F", "#FF4D4F", "#FF4D4F",],
            progress: 99,
            text: "Grave"
        },
        Fraca: {
          strokeColors: ["#52C41A"],
          progress: 33,
          text: "Fraca"
        },
        Moderada: {
            strokeColors: ["#FAAD14", "#FAAD14"],
            progress: 66,
            text: "Moderada"
        },
        Grave: {
            strokeColors: ["#FF4D4F", "#FF4D4F", "#FF4D4F",],
            progress: 99,
            text: "Grave"
        },
        null: {
            strokeColors: ["#52C41A"],
            progress: 33,
            text: "Fraca"
        }
    };

    const selectedOption = options[steps] || options.Fraca;
    return (
      <Progress
        percent={selectedOption.progress}
        steps={3}
        strokeColor={selectedOption.strokeColors}
        format={() => (selectedOption.text)}
      />
    );
};

  const FloodRisk = ({ floodRiskLevel }) => (
    <RiskProgress steps={floodRiskLevel} />
  );

  const GeadaRisk = ({ geadaRiskLevel }) => (
    <SnowRiskProgress steps={geadaRiskLevel} />
  );
  const formatDays = (days) => {
    if (!days || days == null) return "Indisponível";

    if (days === 1) return `${days} dia`;
    
    return `${days} dias`;
};



  return (
    <div className="auraClimaticRiskContainer">
        <div className="auraClimaticRiskTitle">
          Risco climático - 23/24
        </div>
        <Row gutter={[16, 16]} justify="center" style={{ maxWidth: "1000px", margin: "0 auto" }}>
  {/* Coluna da Esquerda */}
  <Col span={12}>
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <div className="auraClimateRisckSection" style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <img src="/icons/filter_drama.svg" alt="Cloud Icon" width={45} height={45} />
          <div>
            <Text className="auraInfoTitle">Chuva na safra</Text>
            <br />
            <span className="auraInfoValue">{`${data?.total_rainfall != null ? `${data.total_rainfall.toFixed(1).replace('.', ',')} mm` : "Indisponível"} - ${formatDays(data?.total_season_days)}`}</span>
          </div>
        </div>
      </Col>

      <Col span={24}>
        <div className="auraClimateRisckSection" style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <img src="/icons/water_drop.svg" alt="Water Drop Icon" width={45} height={45} />
          <div>
            <Text className="auraInfoTitle">Maior janela de chuva</Text>
            <br />
            <span className="auraInfoValue">{`${data?.longest_window_rainfall_sum != null ? `${data.longest_window_rainfall_sum.toFixed(1).replace('.', ',')} mm` : "Indisponível"} - ${formatDays(data?.longest_rainfall_window)}`}</span>
          </div>
        </div>
      </Col>

      <Col span={24}>
        <div className="auraClimateRisckSection" style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <img src="/icons/date_range.svg" alt="Calendar Icon" width={45} height={45} />
          <div>
            <Text className="auraInfoTitle">Dias totais sem chuva</Text>
            <br />
            <span className="auraInfoValue">{`${formatDays(data?.total_days_without_rain)}`}</span>
          </div>
        </div>
      </Col>

      <Col span={24}>
        <div className="auraClimateRisckSection" style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <img src="/icons/event_busy.svg" alt="X Icon" width={45} height={45} />
          <div>
            <Text className="auraInfoTitle">Maior janela de seca</Text>
            <br />
            <span className="auraInfoValue">{`${data.longest_dry_window != null ? formatDays(data?.longest_dry_window) : "Indisponível"}`}</span>
          </div>
        </div>
      </Col>
    </Row>
  </Col>

  {/* Coluna da Direita */}
  <Col span={12}>
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <div className="auraClimateRisckSection" style={{ display: "flex", alignItems: "center", gap: "10px", marginTop:"30px" }}>
          <img src="/icons/ac_unit.svg" alt="Cold Icon" width={45} height={45} />
          <div>
            <Text className="auraInfoTitle">Ocorrência de geada</Text>
            <br />
            <GeadaRisk geadaRiskLevel={data?.snow_intensity} />
          </div>
        </div>
      </Col>

      <Col span={24}>
        <div className="auraClimateRisckSection" style={{ display: "flex", alignItems: "center", gap: "10px", marginTop:"10px" }}>
          <img src="/icons/flood.svg" alt="Flood Icon" width={45} height={45} />
          <div>
            <Text className="auraInfoTitle">Risco de inundação</Text>
            <br />
            <FloodRisk floodRiskLevel={data?.flood_risk_level} />
          </div>
        </div>
      </Col>

      <Col span={24}>
        <div className="auraClimateRisckSection" style={{ display: "flex", alignItems: "center", gap: "10px", marginTop:"10px" }}>
          <img src="/icons/landscape.svg" alt="Landscape Icon" width={45} height={45} />
          <div>
            <Text className="auraInfoTitle">Altitude</Text>
            <br />
            <span className="auraInfoValue">{`Média de ${Math.floor(data?.altitude)} m`}</span>
          </div>
        </div>
      </Col>
    </Row>
  </Col>
</Row>

      </div>

  );
};


export default ClimaticRisk;
