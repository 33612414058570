import React, { useCallback, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import ListCard from "./ListCard";
import { LoadingOutlined } from "@ant-design/icons";
import { Select, Input, Spin, Card } from "antd";
import LoadingPage from "../components/dashboard/LoadingPage";
import "./TicketListing.css";

import wsClient from "./api/websockeetClient";

const { Search } = Input;

function TicketListing({ fetchTickets }) {
    const [items, setItems] = useState([]);
    const [hasMore, setHasMore] = useState(false);
    const [index, setIndex] = useState(2);

    const [searchTerm, setSearchTerm] = useState("");
    const [displayOrder, setDisplayOrder] = useState("-created_at");
    const [isLoadingPage, setIsLoadingPage] = useState(true);
    const [isLoadingFetch, setIsLoadingFetch] = useState(null);

    const [monitoringTickets, setMonitoringTickets] = useState([]);

    // WebSocket setup
    useEffect(() => {
        wsClient.connect("/notifications/");

        const handleWebSocketMessage = (data) => {
            const ticketId = Object.keys(data).find((key) => !isNaN(key));
            if (!ticketId) {
                return;
            }

            const ticketInfo = data[ticketId];

            if (ticketInfo?.risk_calculation_status == "success") {
                setItems((prevItems) =>
                    prevItems.map((item) =>
                        item.id.toString() === ticketId
                            ? {
                                  ...item,
                                  risk_score: ticketInfo.risk_score,
                                  risk_calculation_status:
                                      ticketInfo.risk_calculation_status,
                                  total_classifications: 5,
                              }
                            : item
                    )
                );

                setMonitoringTickets((prev) =>
                    prev.filter((id) => id.toString() !== ticketId)
                );
            }
        };

        wsClient.addListener(handleWebSocketMessage);

        return () => {
            wsClient.removeListener(handleWebSocketMessage);
            wsClient.disconnect();
        };
    }, []);

    // Monitor tickets for calculation status
    useEffect(() => {
        const monitorTicketStatus = () => {
            monitoringTickets.forEach((ticketId) => {
                wsClient.send({ ticket_id: ticketId });
            });
        };

        if (monitoringTickets.length > 0) {
            const intervalId = setInterval(monitorTicketStatus, 10000);

            return () => {
                clearInterval(intervalId);
            };
        }
    }, [monitoringTickets]);

    const getData = useCallback(
        async (term = searchTerm, order = displayOrder) => {
            const response = await fetchTickets(1, term, order);
            setItems(response.results);
            setHasMore(!!response.next);
            setIsLoadingPage(false);
            setIsLoadingFetch(false);

            const calculatingTickets = response.results
                .filter(
                    (item) => item.risk_calculation_status === "calculating"
                )
                .map((item) => item.id);
            setMonitoringTickets((prev) => [
                ...new Set([...prev, ...calculatingTickets]),
            ]);
        },
        [searchTerm, displayOrder]
    );

    useEffect(() => {
        setIsLoadingFetch(true);
        setIndex(2);
        setItems([]);
        getData(searchTerm, displayOrder);
    }, [searchTerm, displayOrder]);

    const fetchMoreData = useCallback(async () => {
        const response = await fetchTickets(index, searchTerm, displayOrder);
        const newItems = response.results.filter(
            (newItem) => !items.some((item) => item.id === newItem.id)
        );

        setItems((prevItems) => [...prevItems, ...newItems]);
        setHasMore(!!response.next);
        setIndex((prevIndex) => prevIndex + 1);

        const calculatingTickets = newItems
            .filter((item) => item.risk_calculation_status === "calculating")
            .map((item) => item.id);
        setMonitoringTickets((prev) => [
            ...new Set([...prev, ...calculatingTickets]),
        ]);
    }, [index, searchTerm, displayOrder, items]);

    const handleRefresh = useCallback(() => {
        setSearchTerm("");
        setDisplayOrder("-created_at");
        setMonitoringTickets([]);
    }, []);

    if (isLoadingPage) {
        return <LoadingPage customMessages={["Carregando"]} />;
    }

    return (
        <div>
            <div className="ticketListingBar">
                <Search onSearch={setSearchTerm} allowClear size="large" />
                <div className="selectorBox">
                    Ordem de exibição:
                    <Select
                        style={{ minWidth: "184px", height: "48px" }}
                        placeholder="Selecione"
                        value={displayOrder}
                        onChange={setDisplayOrder}
                        options={[
                            { value: "-created_at", label: "Mais recentes" },
                            { value: "created_at", label: "Mais antigos" },
                            {
                                value: "-risk_score",
                                label: "Maior score de risco",
                            },
                            {
                                value: "risk_score",
                                label: "Menor score de risco",
                            },
                        ]}
                    />
                </div>
            </div>
            {isLoadingFetch ? (
                <InfiniteScroll style={{ overflow: "unset" }} dataLength={5}>
                    {Array.from({ length: 5 }, (_, index) => (
                        <Card
                            key={index}
                            className="listCardLoadingSkeleton"
                            style={{
                                paddingLeft: "64px",
                                paddingRight: "64px",
                            }}
                            loading={true}
                        />
                    ))}
                </InfiniteScroll>
            ) : items.length > 0 ? (
                <InfiniteScroll
                    style={{ overflow: "unset" }}
                    dataLength={items.length}
                    next={fetchMoreData}
                    hasMore={hasMore}
                    loader={
                        <div style={{ textAlign: "center", padding: "20px 0" }}>
                            <Spin
                                indicator={
                                    <LoadingOutlined
                                        style={{
                                            fontSize: 24,
                                            color: "#B64CFF",
                                        }}
                                        spin
                                    />
                                }
                            />
                        </div>
                    }
                >
                    {items.map((ticket) => (
                        <ListCard
                            data={ticket}
                            key={ticket.id}
                            listUpdateOnDelete={handleRefresh}
                        />
                    ))}
                </InfiniteScroll>
            ) : (
                <div className="emptyTicketHistory">
                    <div>
                        <div>Parece que você ainda não fez consultas,</div>
                        <div>
                            faça um novo envio para que os Tickets comecem a
                            aparecer.
                        </div>
                    </div>
                    <div style={{ display: "flex", gap: "4px" }}>
                        Para fazer uma nova consulta clique no botão de
                        <span
                            style={{
                                backgroundColor: "#9236D9",
                                borderRadius: "50%",
                                height: "36px",
                                width: "36px",
                                display: "inline-flex",
                                alignItems: "center",
                                justifyContent: "center",
                                position: "relative",
                                top: "-4px",
                            }}
                        >
                            <img
                                src={`/assets/icons/icon-kml-upload.svg`}
                                style={{ width: "20px", height: "20px" }}
                            ></img>
                        </span>
                        no menu lateral
                    </div>
                </div>
            )}
        </div>
    );
}

export default TicketListing;
