class WebSocketClient {
    constructor() {
        this.baseURL =
            process.env.REACT_APP_WS_URL || "ws://app.agromai.com.br/api/v1/ws";
        this.socket = null;
        this.listeners = new Set();
    }

    connect(path = "") {
        if (this.socket) return;

        const url = `${this.baseURL}${path}`;
        this.socket = new WebSocket(url);

        this.socket.onopen = () => {
            console.log("WebSocket connection established");
        };

        this.socket.onmessage = (event) => {
            const data = JSON.parse(event.data);
            this.listeners.forEach((listener) => listener(data));
        };

        this.socket.onclose = (event) => {
            if (event.wasClean) {
                console.log("WebSocket connection closed cleanly");
            } else {
                console.error("WebSocket connection closed unexpectedly");
            }
            this.socket = null; // Reset socket for reconnection
        };

        this.socket.onerror = (error) => {
            console.error("WebSocket error:", error);
        };
    }

    disconnect() {
        if (this.socket) {
            this.socket.close();
            this.socket = null;
        }
    }

    send(data) {
        if (this.socket && this.socket.readyState === WebSocket.OPEN) {
            this.socket.send(JSON.stringify(data));
        } else {
            console.warn("WebSocket is not connected");
        }
    }

    addListener(listener) {
        this.listeners.add(listener);
    }

    removeListener(listener) {
        this.listeners.delete(listener);
    }
}

const wsClient = new WebSocketClient();
export default wsClient;
