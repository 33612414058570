import React, {useState, useEffect, useRef} from "react";
import SensePlusMap from "../components/sensePlus/sensePlusMap";
import {fetchSensePlusTickets} from "../components/api/api";
import {Tabs, Segmented} from "antd";
import "./SensePlus.css";
import PlantingProgress from "../components/sensePlus/PlantingChart";
import CropDistributionChart from "../components/sensePlus/CropDistributionChart";
import TicketTable from "../components/sensePlus/SensePlusTable";
import {DownOutlined, UpOutlined} from "@ant-design/icons";
import LoadingPage from "../components/dashboard/LoadingPage";

const processAggregates = (aggregates) => {
    const cropCultures = Object.entries(aggregates.crop_cultures).map(
        ([key, value]) => ({
            name: key,
            area: value.area,
            percentage: value.percentage,
        })
    );

    const cropStages = Object.entries(aggregates.crop_stages).map(
        ([key, value]) => ({
            name: key,
            area: value.area,
            percentage: value.percentage,
        })
    );

    return {cropCultures, cropStages};
};

function SensePlus() {
    const [tickets, setTickets] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [mapboxToken, setMapboxToken] = useState("");
    const [sidebarOpen, setSidebarOpen] = useState(true);
    const [filterString, setFilterString] = useState("");
    const [cropFilter, setCropFilter] = useState("");
    const [cropStageFilter, setCropStageFilter] = useState("");
    const [stateFilter, setStateFilter] = useState("");
    const [bottomBarOpen, setBottomBarOpen] = useState(false);
    const [activeTab, setActiveTab] = useState("1");
    const [selectedSegment, setSelectedSegment] = useState("Monitoramento");
    const [cropCultures, setCropCultures] = useState([]);
    const [cropStages, setCropStages] = useState([]);

    const mapRef = useRef(null);

    const abortControllerRef = useRef(null);

    const segmentedOptions = [
        "Monitoramento",
        "Temperatura",
        "Chuvas",
        "Preço da Terra",
        "Dashboard",
    ].map((label) => ({
        value: label,
        label: <div className="senseMapButtonLabel">{label}</div>,
        disabled: label !== "Monitoramento",
    }));

    const fetchAllTickets = async () => {
        try {
            if (abortControllerRef.current) {
                abortControllerRef.current.abort();
            }

            const controller = new AbortController();
            abortControllerRef.current = controller;

            let page = 1;
            let response;

            setTickets([]);
            setHasMore(true);

            do {
                const fetchWithAbort = async () => {
                    const result = await Promise.race([
                        fetchSensePlusTickets(
                            page,
                            filterString,
                            stateFilter,
                            cropFilter,
                            cropStageFilter
                        ),
                        new Promise((_, reject) =>
                            controller.signal.addEventListener("abort", () =>
                                reject(new DOMException("AbortError"))
                            )
                        ),
                    ]);
                    return result;
                };

                response = await fetchWithAbort();

                if (!mapboxToken) {
                    setMapboxToken(response.mapbox_token);
                    const {cropCultures, cropStages} = processAggregates(
                        response.aggregates
                    );
                    setCropCultures(cropCultures);
                    setCropStages(cropStages);
                }

                const formatLocalization = (lat, long) => {
                    return (
                        <>
                            Latitude: {lat.toFixed(5)} <br/>
                            Longitude: {long.toFixed(5)}
                        </>
                    );
                };

                const filteredResults = response.results.map((ticket) => ({
                    id: ticket.id,
                    clientId: ticket.farmer_name,
                    proposalId: ticket.proposal_id,
                    area: ticket.location_data.hectares,
                    cropType: ticket.crop_type,
                    state: ticket.location_data.state,
                    sicarOverlap: ticket.sicar_overlaps,
                    location: formatLocalization(
                        ticket.location_data.lat,
                        ticket.location_data.long
                    ),
                    long: ticket.location_data.long,
                    lat: ticket.location_data.lat,
                    municipality: ticket.location_data.municipality,
                    notification: ticket.atypical_events,
                }));
                setTickets((prevTickets) => [...prevTickets, ...filteredResults]);

                if (!response.next) setHasMore(false);

                page++;
            } while (response.next);
        } catch (error) {
            if (error.name === "AbortError") {
                console.log("Requisição abortada devido à mudança no filtro.");
            } else {
                console.error("Erro ao carregar tickets:", error);
            }
        }
    };

    useEffect(() => {
        fetchAllTickets();

        return () => {
            if (abortControllerRef.current) {
                abortControllerRef.current.abort();
            }
        };
    }, [filterString, stateFilter, cropFilter, cropStageFilter]);

    const handleCropFilterChange = (label) => {
        if (label !== cropFilter) setCropFilter(label);
        else setCropFilter("");
    };

    const handleCropStageFilterChange = (label) => {
        if (label !== cropStageFilter) setCropStageFilter(label);
        else setCropStageFilter("");
    };

    const toggleSidebar = () => {
        setSidebarOpen((prev) => !prev);
    };

    const toggleBottomBar = () => {
        setBottomBarOpen((prev) => !prev);
    };

    const handleTabChange = (key) => {
        setActiveTab(key);
    };

    const handleSegmentChange = (value) => {
        setSelectedSegment(value);
    };

    const handleSearchFilter = (value) => {
        setStateFilter("");
        setFilterString(value);
    };

    const handleStateFilterChange = (value) => {
        setStateFilter(value);
    };

    if (!mapboxToken) {
        return <LoadingPage customMessages={["Carregando"]}/>;
    }

    return (
        <div style={{backgroundColor: "#141414"}}>
            <div
                style={{
                    display: "flex",
                    alignItems: "stretch",
                    marginLeft: sidebarOpen ? "320px" : "0",
                    background: "#141414",
                    textAlign: "justify",
                }}
            >
                <Segmented
                    options={segmentedOptions}
                    value={selectedSegment}
                    onChange={handleSegmentChange}
                    className="sensePlusSegmented"
                />
            </div>
            <div id="mySidebar" className={`sidebar ${sidebarOpen ? "open" : ""}`}>
                <div className="sidebar-header">
                    <h3
                        style={{
                            fontFamily: "Outfit",
                            fontSize: "20px",
                            fontWeight: 600,
                            lineHeight: "28px",
                            textAlign: "left",
                            textUnderlinePosition: "from-font",
                            textDecorationSkipInk: "none",
                        }}
                    >
                        Painel de Insights
                    </h3>
                </div>
                <div className="sidebar-body">
                    <Tabs
                        className="senseplus-tabs"
                        activeKey={activeTab}
                        onChange={handleTabChange}
                        size="small"
                        tabBarStyle={{margin: 0, color: "#FFF"}}
                        style={{color: "#FFF"}}
                        centered={true}
                    >
                        <Tabs.TabPane tab="Geral" key="1" >
                            <PlantingProgress
                                cropStages={cropStages}
                                cropStageFilter={cropStageFilter}
                                onCropStageFilterChange={handleCropStageFilterChange}
                            />
                            <CropDistributionChart
                                cropCultures={cropCultures}
                                cropFilter={cropFilter}
                                onCropFilterChange={handleCropFilterChange}
                            />
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Casos de Risco" key="2" disabled={true}>
                            <p>Preencher no futuro.</p>
                        </Tabs.TabPane>
                    </Tabs>
                </div>
            </div>
            <div
                id="main"
                style={{
                    marginLeft: sidebarOpen ? "320px" : "0",
                    marginBottom: bottomBarOpen ? "250px" : "50px",
                    transition: "margin 0.5s",
                    height: "calc(100vh - 80px)",
                    overflow: "hidden",
                }}
            >
                <div
                    style={{
                        height: "100%",
                        width: "100%",
                        position: "relative",
                    }}
                >
                    {mapboxToken && (
                        <SensePlusMap
                            tickets={tickets}
                            mapboxToken={mapboxToken}
                            hasMore={hasMore}
                            sidebarOpen={sidebarOpen}
                            toggleSidebar={toggleSidebar}
                            setFilterString={handleSearchFilter}
                            setStateFilter={handleStateFilterChange}
                            mapRef={mapRef}
                        />
                    )}
                </div>
            </div>
            <div>
                {/* Bottom Bar */}
                <div
                    className="bottom-bar"
                    style={{
                        height: bottomBarOpen ? "378px" : "55px",
                        left: sidebarOpen ? "376px" : "0",
                        width: sidebarOpen ? "calc(100% - 376px)" : "100%",
                        position: "fixed",
                        bottom: "0",
                        zIndex: "1000",
                        boxShadow: "0 -2px 5px rgba(0, 0, 0, 0.3)",
                        overflow: "hidden",
                        transition: "height 0.5s, width 0.5s",
                    }}
                >
                    <div
                        className="bottom-bar-header"
                        style={{
                            height: "55px",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            padding: "0 16px",
                            backgroundColor: "#141414",
                            borderBottom: "1px solid #444",
                            zIndex: "1010",
                        }}
                    >
                        <button
                            className="toggle-bottom-bar"
                            onClick={toggleBottomBar}
                            style={{
                                backgroundColor: "#9F45DD",
                                color: "white",
                                border: "none",
                                borderRadius: "4px",
                                padding: "8px 16px",
                                cursor: "pointer",
                                fontSize: "14px",
                                right: "24px",
                            }}
                        >
                            {bottomBarOpen ? (
                                <>
                                    <DownOutlined style={{marginRight: "8px"}}/>
                                    Ocultar Tabela
                                </>
                            ) : (
                                <>
                                    <UpOutlined style={{marginRight: "8px"}}/>
                                    Abrir Tabela
                                </>
                            )}
                        </button>
                    </div>

                    {bottomBarOpen && (
                        <div
                            className="bottom-bar-body"
                            style={{
                                padding: "16px",
                                color: "white",
                                height: "calc(100% - 55px)",
                                overflowY: "auto",
                                marginLeft: sidebarOpen ? "0px" : "40px",
                            }}
                        >
                            <TicketTable data={tickets} mapRef={mapRef} hasMore={hasMore}/>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default SensePlus;
